<template>
  <div class="info-box" :class="typeClass">
    <div class="display-flex">
      <div class="_icon-wrapper">
        <Icon class="-size-24">
          <component :is="iconSVG"></component>
        </Icon>
      </div>
      <div class="_content">
        <h6 class="h6" v-if="headline">{{ headline }}</h6>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue';
import informationSVG from '@/assets/icons/info-outline.svg';

export default {
  name: 'InfoBox',
  props: {
    type: {
      type: String,
      default: 'information',
    },
    headline: String,
  },
  computed: {
    typeClass() {
      return `-${this.type}`;
    },

    iconSVG() {
      return `${this.type}SVG`;
    },
  },
  components: {
    Icon,
    informationSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/info-box.scss"></style>
