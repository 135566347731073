<template>
  <section class="user-vita">
    <ContentBox>
          <div class="row  mb-s">
            <div class="col-12  valign-middle">
              <h4 class="h4 mt-0 mb-0 subheadline font-component-headline">{{ $t('userVita.headline') }}</h4>
            </div>
            <div class="col-12  col-xs-24  valign-middle" :class="{'text-right': !$matchMedia.xs}" v-if="(!editModeOnly || userEdited) && keep_old_cv">
              <Button class="-grey  -thin  ml-xs  ml-xs-0  mt-xs-xs" @click.native="switchToEditMode" v-if="!editModeOnly && !editMode && canEdit">
                {{ $t('userVita.buttons.edit') }}
              </Button>
              <Button class="-blue  -thin  mt-xs-xs" @click.native="updateUserVita" v-if="!editModeOnly && editMode && canEdit" :disabled="!userEdited">
                {{ $t('userVita.buttons.save') }}
              </Button>
              <Button class="-grey  -thin  ml-xs" @click.native="resetInput" v-if="userEdited">
                <Icon class="-size-20"><ResetSVG /></Icon>
              </Button>
              <Button class="-grey  -thin  ml-xs" @click.native="cancelEdit" v-if="!editModeOnly && editMode">
                <Icon class="-size-20"><CloseSVG></CloseSVG></Icon>
              </Button>
            </div>
            <div class="col-12  text-right" v-else>
              <Button class="-orange  -outlined  -compact" @click.native="$emit('switchCvView')">{{ $t('userCv.buttons.switchBack') }}</Button>
            </div>
          </div>

          <div class="row  mb-s" v-if="keep_old_cv">
            <div class="col-24">
              <InfoBox type="information" :headline="$t('userVita.oldCv.headline')">
                <p>{{ $t('userVita.oldCv.paragraph') }}</p>
                <Link class="-orange" @click.native="migrateToNewCV">{{ $t('userVita.oldCv.link') }}</Link>
              </InfoBox>
            </div>
          </div>

      <div class="row">
            <div class="col-24">

              <div class="vita-editor" :class="editorClasses">
                <div class="vita-editor-toolbar" v-if="editor !== null && editMode === true">

                  <Button class="-compact  color-blue-600"
                          @click.native="editor.chain().focus().undo().run()"
                          :class="{ 'is-active': editor.isActive('undo') }"
                          title="Strg+Z"
                  >
                    <Icon class="-size-18"><UndoIcon /></Icon>
                  </Button>

                  <Button class="-compact  color-blue-600"
                          @click.native="editor.chain().focus().redo().run()"
                          :class="{ 'is-active': editor.isActive('redo') }"
                          title="Strg+Y"
                  >
                    <Icon class="-size-18"><RedoIcon /></Icon>
                  </Button>

                  <Button class="-compact  color-blue-600  ml-xs"
                          @click.native="editor.chain().focus().toggleHeading({ level: 6 }).run()"
                          :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
                  >
                    <Icon class="-size-14"><FormatFontSizeIcon /></Icon>
                  </Button>

                  <Button class="-compact  color-blue-600"
                          @click.native="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                          :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
                  >
                    <Icon class="-size-18"><FormatFontSizeIcon /></Icon>
                  </Button>

                  <Button class="-compact  color-blue-600"
                          @click.native="editor.chain().focus().toggleBold().run()"
                          :class="{ 'is-active': editor.isActive('bold') }"
                          title="Strg+B"
                  >
                    <Icon class="-size-18"><FormatBoldIcon /></Icon>
                  </Button>

                  <Button class="-compact  color-blue-600"
                          @click.native="editor.chain().focus().toggleItalic().run()"
                          :class="{ 'is-active': editor.isActive('italic') }"
                          title="Strg+I"
                  >
                    <Icon class="-size-18"><FormatItalicIcon /></Icon>
                  </Button>

                  <Button class="-compact  color-blue-600"
                          @click.native="editor.chain().focus().toggleBulletList().run()"
                          :class="{ 'is-active': editor.isActive('bulletList') }"
                  >
                    <Icon class="-size-18"><FormatBulletListIcon /></Icon>
                  </Button>

                </div>

                <editor-content style="width: 100%;" ref="editor" :editor="editor" />
              </div>

            </div>
          </div>

    </ContentBox>
  </section>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';
import { STATUS } from '@/constants';
import { updateUser } from '@/api/users.api';
import parseDates from '@/mixins/parseDates';
import Button from '@/components/atoms/Button.vue';
import Link from '@/components/atoms/Link.vue';
import InfoBox from '@/components/elements/InfoBox.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import Icon from '@/components/atoms/Icon.vue';
import CloseSVG from '@/assets/icons/close.svg';
import ResetSVG from '@/assets/icons/reset.svg';

export default {
  name: 'UserVita',
  mixins: [parseDates, validationMixin],
  props: {
    uuid: String,
    vita: String,
    editModeOnly: {
      type: Boolean,
      default: false,
    },
    useTemplate: {
      type: Boolean,
      default: false,
    },
    keep_old_cv: Boolean,
  },

  validations: {
    vita: {},
  },

  data() {
    return {
      editMode: this.editModeOnly,
      editor: null,
      editedVita: '',
      updateStatus: '',
      vitaTemplate: this.$t('userVita.template'),
    };
  },

  computed: {
    template() {
      return this.$t('userVita.template');
    },

    ...mapGetters([
      'userCan',
    ]),

    userEdited() {
      if (this.vita === null && this.useTemplate) {
        return (this.editedVita !== this.vitaTemplate);
      }

      return (this.editedVita !== this.vita);
    },

    canEdit() {
      return this.userCan('edit', this.uuid) || this.userCan('updateUserVita');
    },

    isTemplate() {
      return (this.useTemplate && this.editedVita === this.vitaTemplate);
    },

    editorClasses() {
      return {
        'is-template': this.isTemplate,
        'is-editable': this.editMode,
      };
    },
  },

  created() {
    // Copy user vita into component state
    if (this.vita === null && this.useTemplate) {
      this.editedVita = this.vitaTemplate;
    } else {
      this.editedVita = this.vita;
    }
  },

  mounted() {
    this.editor = new Editor({
      content: this.editedVita,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [6, 5],
          },
          blockquote: false,
          code: false,
          codeBlock: false,
          collaboration: false,
          dropcursor: false,
          horizontalRule: false,
          strike: false,
        }),
      ],
      editable: this.editMode,
      onUpdate: this.onUpdatedContent,
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  watch: {
    editMode() {
      this.editor.setEditable(this.editMode);
    },
  },

  methods: {
    forceSetContent(content) {
      this.editor.commands.setContent(content);
    },

    cancelEdit() {
      if (this.userEdited) {
        this.editedVita = this.vita;
      }

      this.editMode = false;
    },

    switchToEditMode() {
      if (this.canEdit && !this.editMode) {
        this.editMode = true;
      }
    },

    updateUserVita() {
      if (!this.canEdit || !this.userEdited) {
        return;
      }

      this.updateStatus = STATUS.PENDING;

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          updateUser(this.uuid, { vita: this.editedVita }, token)
            .then(() => {
              this.updateStatus = STATUS.OK;
              this.editMode = false;
              this.$emit('update');
              this.$eventBus.$emit('notificate');
            })
            .catch((error) => {
              this.updateStatus = STATUS.ERROR;
              this.$eventBus.$emit('notificate', {
                message: error.response.data.message,
                status: error.response.status,
              });
            });
        });
    },

    confirmAction(action) {
      // eslint-disable-next-line
      return window.confirm(`${this.$t(`userVita.oldCv.actions.${action}`)}?`);
    },

    migrateToNewCV() {
      if (!this.confirmAction('migrateToNewCV')) {
        return;
      }

      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          updateUser(this.uuid, { keep_old_cv: false }, token)
            .then(() => {
              this.$emit('update');
              this.$eventBus.$emit('notificate');
            })
            .catch((error) => {
              this.$eventBus.$emit('notificate', {
                message: error.response.data.message,
                status: error.response.status,
              });
            });
        });
    },

    resetInput() {
      let resetValue = null;

      if (this.vita === null && this.useTemplate) {
        resetValue = this.vitaTemplate;
      } else {
        resetValue = this.vita;
      }

      this.editedVita = resetValue;
      this.forceSetContent(resetValue);
      this.$emit('input', { user: { vita: resetValue } });
    },

    onUpdatedContent(ev) {
      const newContent = ev.editor.getHTML();

      if (newContent === '<p></p>') {
        this.editedVita = null;
      } else {
        this.editedVita = newContent;
      }

      this.$emit('input', { user: { vita: this.editedVita } });
    },
  },

  components: {
    Link,
    InfoBox,
    ContentBox,
    Button,
    Icon,
    CloseSVG,
    ResetSVG,

    EditorContent,
    FormatBoldIcon: () => import('@/assets/icons/editor/format-bold.svg'),
    FormatItalicIcon: () => import('@/assets/icons/editor/format-italic.svg'),
    FormatBulletListIcon: () => import('@/assets/icons/editor/format-bullet-list.svg'),
    FormatFontSizeIcon: () => import('@/assets/icons/editor/format-font-size.svg'),
    UndoIcon: () => import('@/assets/icons/editor/undo.svg'),
    RedoIcon: () => import('@/assets/icons/editor/redo.svg'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/user-vita.scss"></style>
